import { useNuxtApp } from '#app'
import { filterEmptyObjectFields } from '~/helpers'

export const useHttpClient: typeof useFetch = async (
    url,
    options
) => {
    const runtimeConfig = useRuntimeConfig()
    const { locale } = useNuxtApp().$i18n
    let headers = { 'accept-language': locale.value || 'en' }

    if (options && options?.customHeaders) {
        headers = { ...headers, ...options.customHeaders }
    }

    const { data, error, pending, refresh, status } = await useFetch(
        url,
        {
            baseURL: runtimeConfig.public.baseURL,
            headers,
            initialCache: false,
            ...options,
            query: filterEmptyObjectFields(options?.query)
        }
    )

    console.info(
        `FETCH=>${url} LOCALE:${locale.value} OPTIONS: `,
        filterEmptyObjectFields(options?.query),
        'DATA :=>',
        data?.value
    )

    if (error.value) {
        console.error('ERROR', error.value)
    }

    if (error?.value?.statusCode === 404) {
        showError({
            fatal: true,
            statusCode: 404,
            statusMessage: 'Page Not Found'
        })
    }
    return { data, error, pending, refresh, status }
}
