export function areAllCharactersNumbers(str) {
    return /^[0-9]+$/.test(str)
}

export function cloneDeep(obj: any) {
    return JSON.parse(JSON.stringify(obj))
}

interface MyObject {
    [key: string]: any // Update with a more specific type if possible
}

export const filterEmptyObjectFields = (obj: MyObject): MyObject => {
    if (!obj) return {}

    return Object.fromEntries(
        Object.entries(obj)
            .map(([key, value]) => [
                key,
                Array.isArray(value)
                    ? value.filter(
                          v =>
                              v !== null &&
                              v !== undefined &&
                              v !== ''
                      )
                    : value !== null &&
                        value !== undefined &&
                        value !== ''
                      ? value
                      : null // Preserve numeric values including 0
            ])
            .filter(
                ([, value]) =>
                    value !== null &&
                    value !== undefined &&
                    (!Array.isArray(value) || value.length)
            )
    )
}

export function formatDate(date: string, separator = '.') {
    const d = new Date(date)

    const formats = {
        '.': `${padTo2Digits(d.getDate())}.${padTo2Digits(
            d.getMonth() + 1
        )}.${d.getFullYear()}`,
        '-': `${d.getFullYear()}-${padTo2Digits(
            d.getMonth() + 1
        )}-${padTo2Digits(d.getDate())}`
    }

    return (
        formats[separator] ||
        (console.error(
            'Unsupported separator. Please use either a dot (.) or a dash (-).'
        ),
        null)
    )
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0')
}

export function formatNumber(number: number) {
    const formattedNumber = Intl.NumberFormat().format(number)
    const numberWithWhitespaces = formattedNumber.replace(/,/g, ' ')

    return numberWithWhitespaces
}
